<template>
    <div class="pl-4 pr-4 pb-3">
        <div class="mt-4" style="display: flex; justify-content: space-between;">
            <template v-for="(step, index) in steps">
                <div style="text-align: center;" class="d-flex justify-content-center">
                    <CButton
                        :class="{ 'step-button-active': progressValue >= step.threshold, 'step-button': progressValue < step.threshold }">
                        <span v-if="progressValue > step.threshold" class="mt-1"><i class="fi fi-rr-check"></i></span>
                        <span v-else>{{ index + 1 }}</span>
                    </CButton>
                    <div class="step-label">{{ $t(stepLabels[index]) || '' }}</div>
                </div>
                <div v-if="index < steps.length - 1" style="width: 100%;" class="pt-3 pl-1 pr-1">
                    <CProgress color="success" :value="progressBar[index + 1]" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { CButton, CProgress } from '@coreui/vue';

export default {
    components: {
        CButton,
        CProgress
    },
    props: {
        stepNum: {
            type: Number,
            required: true,
        },
        icons: {
            type: Array,
            required: false
        },
        currentStep: {
            type: Number,
            required: true,
            default: 0
        },
        stepLabels: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            progressValue: 0, // Start with initial progress of 0
            progressBar: [],
        }
    },
    computed: {
        steps() {
            const thresholds = Array.from({ length: this.stepNum }, (_, i) => (i + 1) * (100 / this.stepNum));
            return thresholds.map(threshold => ({ threshold }));
        }
    },
    watch: {
        currentStep(newVal) {
            this.setProgressStep(newVal);
        }
    },
    mounted() {
        this.setProgressStep(this.currentStep);
    },
    methods: {
        setProgressbar() {
            this.progressBar = this.steps.map(step => this.progressValue >= step.threshold ? 100 : 0);
        },
        setProgressStep(stepNumber) {
            if (stepNumber > this.stepNum || stepNumber < 1) return;
            this.progressValue = stepNumber * (100 / this.stepNum);
            this.setProgressbar();
        }
    }
}
</script>

<style>
.stepbar {
    width: 100%;
}

.progress {
    height: 8px;
}

.step-button {
    font-size: large;
    width: 40px;
    height: 40px;
    z-index: 1020;
    background-color: #eeeeee;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.step-button-active {
    font-size: large;
    width: 40px;
    height: 40px;
    z-index: 1020;
    border-radius: 50%;
    color: #fff;
    background-color: var(--success);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-label {
    font-size: small;
    color: gray;
    margin-top: 50px;
    white-space: nowrap;
    position: absolute;

}

.bg-success {
    background-color: var(--success) !important;
}

@media (max-width: 768px) {
    .step-label {
        display: none;
    }
}
</style>
