<template>
    <div v-if="!loadingPage">
        <select-shop :loadingButton="!loadingButton" :backButton="true"></select-shop>
        <div v-if="isPermission">
            <CCard class="shadow-sm">
                <CCardBody class="p-4">
                    <div class="w-100 text-center promotion-create-header">
                        <AppStepProgressBar :stepLabels="stepLabels" :currentStep="currentSection + 1" class="mb-5 mt-4"
                            :stepNum="5" />
                        <h3 class="mt-2">{{ $t(headerDisplay[currentSection].title) }}</h3>
                        <span style="color: gray;" v-if="headerDisplay[currentSection].description !== ''"
                            class="mr-5 ml-4">{{ $t(
                                headerDisplay[currentSection].description) }}
                        </span>
                    </div>
                    <div v-show="currentSection == 0" class="promotion-create-1 row p-3 ">
                        <div class="scrollable-container w-100 d-flex flex-nowrap mt-2 mb-2">
                            <div v-for="option in promotionTypeOptions" @click="setPromotionTypeOption(option.type)"
                                :class="getPromotionTypeOptionClass(option.type)">
                                <span>{{ $t(option.type) }}</span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div v-for="promotion in getPromotionTypeByOption()" class="col-sm-12 col-md-4 p-2">
                                <div @click="setPromotionType(promotion.type)" class="promotion-card-option btn p-0 ">
                                    <img style="aspect-ratio: 16/9;" width="100%" :src="promotion.img">
                                    <div class="text-left p-2 pt-3">
                                        <h5 v-html="$t(promotion.type)"></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="currentSection == 1" class=" p-2">
                        <div class="scrollable-container w-100 d-flex flex-nowrap mt-3 mb-3">
                            <div v-for="type in conditionTypeOptions" @click="setConditionTypeOption(type.type)"
                                :class="getConditionTypeOptionClass(type.type)">
                                <span>{{ $t(type.type) }}</span>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-2">
                            <div v-for="condition in getConditions()" class="col-sm-12 col-md-4 p-2 ">
                                <div @click="setPromotionCondition(condition.type)"
                                    class="promotion-card-option btn p-0 ">
                                    <img style="aspect-ratio: 16/9;" width="100%" :src="condition.img">
                                    <div class="text-left p-2 pt-3">
                                        <h5>{{ $t(condition.type) }}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentSection >= 2" class=" text-dark w-100 ">
                        <div v-show="currentSection == 2">
                            <div class="w-100 text-center">{{ $t(promotion.condition) }}</div>
                            <AppPromotionConditionForm :key="'AppPromotionConditionForm' + refreshKey"
                                ref="conditionComponent" :conditionType="promotion.condition">
                            </AppPromotionConditionForm>

                            <div class="row">
                                <div class="col-6">
                                    <CButton @click="backwardSection()" block color="light">{{ $t('back')
                                        }}</CButton>
                                </div>
                                <div class="col-6">
                                    <CButton @click="nextStage(3)" block color="success">{{ $t('next') }}
                                    </CButton>
                                </div>
                            </div>
                        </div>
                        <div v-show="currentSection == 3">
                            <div class="w-100 text-center">{{ $t(promotion.type) }}</div>
                            <AppPromotionResultForm :key="'AppPromotionResultForm' + refreshKey" ref="resultsComponent"
                                :promotionType="promotion.type">
                            </AppPromotionResultForm>
                            <div class="row">
                                <div class="col-6">
                                    <CButton @click="backwardSection()" block color="light">{{ $t('back')
                                        }}</CButton>
                                </div>
                                <div class="col-6">
                                    <CButton @click="nextStage(4)" block color="success">{{ $t('next') }}
                                    </CButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="currentSection == 4" class=" text-dark w-100 ">
                        <AppPromotionDetailsForm ref="detailsComponent" :isEmptyForm="true" />
                        <div class="row">
                            <div class="col-6">
                                <CButton @click="backwardSection()" block color="light">{{ $t('back')
                                    }}</CButton>
                            </div>
                            <div class="col-6">
                                <CButton :disabled="loading" @click="toggleSaveModal()" block color="success">
                                    <CSpinner v-if="loading" color="white" size="sm" />
                                    {{
                                        $t('save') }}
                                </CButton>
                            </div>
                        </div>
                    </div>
                    <CButton @click="backwardSection()" v-if="currentSection !== 0 && currentSection < 2" block
                        color="light">{{ $t('back')
                        }}</CButton>
                </CCardBody>
            </CCard>
            <CModal :show.sync="saveModal" centered :title="$t('confirmSavePromotionHeader')" color="success"
                header="false">
                <div class="text-center p-3">
                    <h4>{{ $t('confirmCreatePromotion') }}</h4>
                    <span style="color: gray" v-html="$t('confirmSavePromotionDesp')" />
                    <!-- <div class="json-display">
                        <pre>{{ prettyJson(promotionData) }}</pre>
                    </div> -->
                </div>
                <template #footer>
                    <div class="w-100 d-flex justify-content-center">
                        <CButton :disabled="loading" style="width: 50%;margin-right: 1cap;" @click="saveModal = false"
                            color="secondary">
                            <b>{{ $t("cancel") }}</b>
                        </CButton>
                        <CButton :disabled="loading" style="width: 50%;margin-left: 1cap;" @click="savePromotion()"
                            color="success">
                            <CSpinner v-if="loading" color="white" size="sm" />
                            <b>{{ $t("submit") }}</b>
                        </CButton>
                    </div>
                </template>
            </CModal>
        </div>
        <div v-else>
            <access-data></access-data>
        </div>
    </div>
    <div v-else>
        <CCard>
            <CCardBody style="padding-top: 20cap;">
                <div style="width: 100%;height: 100vh;display: flex;justify-content: center;">
                    <CSpinner color="success"></CSpinner>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import permis from '@/util/permission'
import AppPromotionConditionForm from '@/components/AppPromotionConditionForm';
import AppPromotionResultForm from '@/components/AppPromotionResultForm';
import AppStepProgressBar from '@/components/AppStepProgressBar';
import { CButton } from '@coreui/vue';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-datepicker/index.css'
import 'vue2-timepicker/dist/VueTimepicker.css'
import moment from 'moment'
import pos from '@/services/local';
import '@/assets/styles/promotion.css'
import AppPromotionDetailsForm from '@/components/AppPromotionDetailsForm';
import util from '@/util/util'

export default {
    components: {
        AppPromotionConditionForm,
        AppPromotionResultForm,
        VueTimepicker,
        AppStepProgressBar,
        AppPromotionDetailsForm
    },
    data() {
        return {
            loadingButton: false,
            openHelpModal: false,
            currentSection: 0,
            loading: false,
            headerDisplay: [
                {
                    title: 'selectPromotion',
                    description: 'selectPromotionDesp'
                },
                {
                    title: 'selectCondition',
                    description: 'selectConditionDesp'
                },
                {
                    title: 'conditionHeader',
                    description: ''
                },
                {
                    title: 'discount',
                    description: ''
                },
                {
                    title: 'inputPromotionDetails',
                    description: 'inputPromotionDetailsDesp'
                },
            ],
            promotionItemsType: [
                { type: 'ITEM_FIXED_PRICE', name: 'Item Fixed Price', img: '/img/promotion/fixItem.jpg' },
                { type: 'FREE_ITEM', name: 'Free Item', img: '/img/promotion/freeItem.jpg' },
                { type: 'ITEM_PERCENTAGE_DISCOUNT', name: 'Item Percentage Discount', img: '/img/promotion/percentageItem.jpg' },
                { type: 'ITEM_DOLLAR_DISCOUNT', name: 'Item Dollar Discount', img: '/img/promotion/dollarItem.jpg' },
                // { type: 'ITEM_X_FREE_Y', name: 'Item X Free Y', img: 'path/to/image5.png' },
            ],
            promotionCategoryType: [
                { type: 'CATEGORY_FIXED_PRICE', name: 'Category Fixed Price', img: '/img/promotion/fixCategory.jpg' },
                { type: 'CATEGORY_PERCENTAGE_DISCOUNT', name: 'Category Percentage Discount', img: '/img/promotion/percentageCategory.jpg' },
                { type: 'CATEGORY_DOLLAR_DISCOUNT', name: 'Category Dollar Discount', img: '/img/promotion/dollarCategory.jpg' },
                // { type: 'CATEGORY_X_FREE_Y', name: 'Category X Free X', img: 'path/to/image9.png' },
            ],
            promotionReceiptType: [
                { type: 'RECEIPT_PERCENTAGE_DISCOUNT', name: 'Receipt Percentage Discount', img: '/img/promotion/receiptPercentage.jpg' },
                { type: 'RECEIPT_DOLLAR_DISCOUNT', name: 'Receipt Dollar Discount', img: '/img/promotion/receiptDollar.jpg' }
            ],
            selectedPromotionTypeOption: null,
            promotionTypeOptions: [
                { type: 'ITEM_DISCOUNT_OPTION', description: '', img: '' },
                { type: 'CATEGORY_DISCOUNT_OPTION', description: '', img: '' },
                { type: 'RECEIPT_DISCOUNT_OPTION', description: '', img: '' },
            ],

            conditionTypeOptions: [
                { type: 'QUANTITY_EXCEED_OPTION', description: 'QUANTITY_EXCEED_OPTION_DESP', img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png' },
                { type: 'PRICE_EXCEED_OPTION', description: 'PRICE_EXCEED_OPTION_DESP', img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png' },
                // { type: 'BUNDLE_OPTION', description: 'BUNDLE_OPTION_DESP', img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png' },
                { type: 'NO_CONDITION', description: 'NO_CONDITION_DESP', img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png' },
            ],
            selectedConditionTypeOption: null,
            bundleConditions: [
                { type: 'BUNDLE_ITEM', description: 'BUNDLE_ITEM_DESP', img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png' },
                { type: 'BUNDLE_CATEGORY', description: 'BUNDLE_CATEGORY_DESP', img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png' },
            ],
            quantityExceedConditions: [
                { type: 'QUANTITY_ITEM_EXCEED', description: 'QUANTITY_ITEM_EXCEED_DESP', img: '/img/promotion/conditions/qtyItem.jpg' },
                { type: 'QUANTITY_CATEGORY_EXCEED', description: 'QUANTITY_CATEGORY_EXCEED_DESP', img: '/img/promotion/conditions/qtyCategory.jpg' },
                { type: 'QUANTITY_RECEIPT_EXCEED', description: 'QUANTITY_RECEIPT_EXCEED_DESP', img: '/img/promotion/conditions/qtyReceipt.jpg' },
            ],
            priceExceedConditions: [
                { type: 'PRICE_ITEM_EXCEED', description: 'PRICE_ITEM_EXCEED_DESP', img: '/img/promotion/conditions/priceItem.jpg' },
                { type: 'PRICE_CATEGORY_EXCEED', description: 'PRICE_CATEGORY_EXCEED_DESP', img: '/img/promotion/conditions/priceCategory.jpg' },
                { type: 'PRICE_RECEIPT_EXCEED', description: 'PRICE_RECEIPT_EXCEED_DESP', img: '/img/promotion/conditions/priceReceipt.jpg' },
            ],
            promotion: {
                type: '',
                condition: '',
                name: '',
                description: '',
                img: '',
            },
            saveModal: false,
            promotionData: {},
            refreshKey: 0,
            stepLabels: ['stepPromotion1', 'stepPromotion2', 'stepPromotion3', 'stepPromotion4', 'stepPromotion5'],
            orderShopSetting: {},
            loadingPage: false
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date', 'permissions']),
        isPermission() {
            // return permis.findPermissionRead('inventory', this.$route.path)
            const shopObjectId = this.shopObjectId
            const selectedShop = this.shops.find(shop => shop.objectId === shopObjectId) || null
            return selectedShop?.currentPlan == "multiplePOS" && this.orderShopSetting?.isGrabOrderEnabled == true
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId;
            },
            set(newValue) {
                return this.$store.dispatch("setShop", newValue);
            },
        },
        uid() {
            return localStorage.getItem('uid')
        },
    },
    created() {
        this.getOrderShopSetting()
    },
    methods: {
        getPromotionTypeByOption() {
            switch (this.selectedPromotionTypeOption) {
                case 'ITEM_DISCOUNT_OPTION':
                    return this.promotionItemsType
                case 'CATEGORY_DISCOUNT_OPTION':
                    return this.promotionCategoryType
                case 'RECEIPT_DISCOUNT_OPTION':
                    return this.promotionReceiptType
                default:
                    this.selectedPromotionTypeOption = 'ITEM_DISCOUNT_OPTION' //SET DEFAULT
                    return this.promotionItemsType;
            }
        },
        setPromotionTypeOption(type) {
            this.selectedPromotionTypeOption = type
        },
        setPromotionType(type) {
            const skipCondition = ['ITEM_X_FREE_Y', 'CATEGORY_X_FREE_Y']
            if (skipCondition.includes(type)) {
                this.promotion.type = type
                this.currentSection = 3
            } else {
                this.promotion.type = type
                this.currentSection = 1
            }

        },
        getPromotionTypeOptionClass(type) {
            if (this.selectedPromotionTypeOption == type) {
                return 'condition-type-option-selected '
            } else {
                return 'condition-type-option'
            }
        },
        setPromotionCondition(type) {
            this.promotion.condition = type
            if (type == 'NO_CONDITION') {
                this.currentSection = 3
            } else {
                this.currentSection = 2
            }

        },
        async toggleSaveModal() {
            this.loading = true
            await this.makePromotionData()
            if (this.promotionData.details.title == null || this.promotionData.details.title == '') {
                this.promotionData.details.title = this.getSuggestPromotionTitle(this.promotionData.type, this.promotionData.results)
            }
            if (this.promotionData.details.description == null || this.promotionData.details.description == '') {
                this.promotionData.details.description = this.getSuggestPromotionDescription(this.promotionData.condition)
            }
            if (this.promotionData.details.remoteImagePath == null || this.promotionData.details.remoteImagePath == '') {
                this.getBase64Image(this.promotionData.type)
            }
            if (this.validatePromotionData()) {
                this.saveModal = true
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                alert(this.$t('validateInfomation'))
                this.validateDetails()
            }
            this.loading = false
        },
        async makePromotionData() {
            this.promotionData.type = this.promotion.type
            this.promotionData.results = this.$refs.resultsComponent.getResults()
            this.promotionData.condition = this.$refs.conditionComponent.getCondition()
            const updatedDetails = this.$refs.detailsComponent.getDetails();
            this.promotionData = {
                ...this.promotionData,
                ...updatedDetails
            };
            if (this.promotion.type == 'ITEM_X_FREE_Y') {
                let resultInput = this.$refs.resultsComponent.getResults()
                this.promotionData.condition = resultInput[0]
                this.promotionData.condition.type = this.promotion.type
            } else if (this.promotion.type == 'CATEGORY_X_FREE_Y') {
                let resultInputCategory = this.promotionData.results
                let resultData = {}

                if (resultInputCategory[0]?.categories && resultInputCategory[0]?.categories[0]?.id) {
                    resultData = {
                        "category": {
                            "id": resultInputCategory[0].categories[0].id,
                            "objectId": resultInputCategory[0].categories[0].objectId,
                            "name": resultInputCategory[0].categories[0].name,
                        },
                        "freeItemQuantity": resultInputCategory[0].freeItemQuantity,
                        "inCartQuantity": resultInputCategory[0].inCartQuantity,
                        "type": this.promotion.type,
                    }
                } else {
                    resultData = {
                        "category": {
                            "id": resultInputCategory[0].category.id,
                            "objectId": resultInputCategory[0].category.objectId,
                            "name": resultInputCategory[0].category.name,
                        },
                        "freeItemQuantity": resultInputCategory[0].freeItemQuantity,
                        "inCartQuantity": resultInputCategory[0].inCartQuantity,
                        "type": this.promotion.type,
                    }
                }
                this.promotionData.results[0] = resultData
                this.promotionData.condition = resultData
            }
        },
        prettyJson(jsonData) {
            return JSON.stringify(jsonData, null, 2);
        },
        getConditionTypeOptionClass(type) {
            if (this.selectedConditionTypeOption == type) {
                return 'condition-type-option-selected '
            } else {
                return 'condition-type-option'
            }
        },
        setConditionTypeOption(type) {
            this.selectedConditionTypeOption = type
        },
        getConditions() {
            const type = this.selectedConditionTypeOption
            switch (type) {
                case 'BUNDLE_OPTION':
                    return this.bundleConditions
                case 'QUANTITY_EXCEED_OPTION':
                    return this.quantityExceedConditions
                case 'PRICE_EXCEED_OPTION':
                    return this.priceExceedConditions
                case 'NO_CONDITION':
                    return [
                        { type: 'NO_CONDITION', description: 'NO_CONDITION_DESP', img: '/img/promotion/conditions/noCondition.jpg' },
                    ]
                default:
                    this.selectedConditionTypeOption = 'QUANTITY_EXCEED_OPTION'
                    return
            }
        },
        backwardSection() {
            const skipCondition = ['ITEM_X_FREE_Y', 'CATEGORY_X_FREE_Y']
            if (this.currentSection === 1) {
                this.promotion.type = '';
            }
            else if (this.currentSection === 3 && skipCondition.includes(this.promotion.type)) {
                this.promotion.condition = '';
                this.currentSection = 0;
                return
            }
            else if (this.currentSection === 3 && this.promotion.condition == 'NO_CONDITION') {
                this.currentSection = 1;
                return
            }
            this.currentSection -= 1;
        },
        savePromotion() {
            this.loading = true
            const headers = {
                shopObjectId: this.shopObjectId,
                uid: this.uid,
            }
            pos
                .post(`/api/v3.0/shop/promotion`, this.promotionData, { headers, headers })
                .then((res) => {
                    this.$router.push('/promotion')
                    this.saveModal = false;
                    this.loading = false
                })
                .catch((error) => {
                    console.error("Error saving promotion:", error);
                    alert("Error saving promotion");
                    this.saveModal = false;
                    this.loading = false
                });
        },

        nextStage(i) {
            if (i === 3) {
                const form = this.$refs.conditionComponent.getCondition();
                const isConditionValid = Object.entries(form).every(([key, value]) => {
                    if (Array.isArray(value)) {
                        return value.length > 0;
                    } else if (typeof value === 'number') {
                        return value >= 0;
                    }
                    return value !== null && value !== '' && value !== undefined;
                });

                if (!isConditionValid) {
                    alert(this.$t('validateInfomation'));
                    return;
                }
            } else if (i === 4) {
                const resultsForm = this.$refs.resultsComponent.getResults();
                const isResultsValid = resultsForm.every(result => {
                    const baseValidation = Object.entries(result).every(([key, value]) => {
                        if (key === 'quantityLimit') {
                            // Handle empty string, NaN, or undefined by setting it to -1 (default to unlimited)
                            if (value === '' || isNaN(value) || value === undefined) {
                                value = -1;
                            }
                            return value === -1 || value >= 0; // Allow -1 or any non-negative number
                        }
                        if (key === 'cap') {
                            // Handle empty string, NaN, or undefined by setting it to -1 (default to unlimited)
                            if (value === '' || isNaN(value) || value === undefined) {
                                value = 9999999;
                            }
                            return value === -1 || value >= 0; // Allow -1 or any non-negative number
                        }

                        if (Array.isArray(value)) {
                            return value.length > 0; // Ensure arrays are not empty
                        } else if (typeof value === 'number') {
                            return value >= 0; // Ensure numbers are non-negative
                        }

                        return value !== null && value !== '' && value !== undefined; // Validate other types
                    });


                    // Additional check for `ITEM_FIXED_PRICE` type
                    if (this.promotion.type === 'ITEM_FIXED_PRICE') {
                        const fixedPriceValid = result.items.every(item =>
                            typeof item.fixedPrice === 'number' && item.fixedPrice >= 0
                        );
                        return baseValidation && fixedPriceValid;
                    }
                    return baseValidation;
                });

                if (!isResultsValid) {
                    alert(this.$t('validateInfomation'));
                    return;
                }
            }
            this.currentSection = i;
        },
        validatePromotionData() {
            const isValid = Object.entries(this.promotionData).every(([key, value]) => {
                if (value === null || value === '' || value === undefined) {
                    console.warn(`Field ${key} is invalid: ${value}`);
                    return false;
                }

                // Additional check if value is an object, to handle nested validations (e.g., details, condition)
                if (typeof value === 'object' && !Array.isArray(value)) {
                    return Object.entries(value).every(([subKey, subValue]) => {
                        // Skip validation for `remoteImagePath` in `details`
                        if (key === 'details' && subKey === 'remoteImagePath') {
                            return true;
                        }
                        if (subValue === null || subValue === '' || subValue === undefined) {
                            console.warn(`Field ${key}.${subKey} is invalid: ${subValue}`);
                            return false;
                        }
                        return true;
                    });
                }

                // For arrays, ensure each element is not null, empty, undefined, and length is not 0
                if (Array.isArray(value)) {
                    if (value.length === 0) {
                        console.warn(`Field ${key} is invalid: Array length is 0`);
                        return false;
                    }
                    return value.every(item => item !== null && item !== '' && item !== undefined);
                }

                return true;
            });

            // Additional check for startDate and endDate
            if (isValid) {
                const { startDate, endDate } = this.promotionData;
                if (startDate && endDate) {
                    const start = new Date(startDate);
                    const end = new Date(endDate);

                    if (start > end) {
                        console.warn("Validation failed: startDate cannot be after endDate.");
                        return false;
                    }
                }
            }

            return isValid;
        },
        getOrderShopSetting() {
            this.loadingPage = true
            const uid = this.uid
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'get',
                url: '/api/v2.0/' + uid + '/OrderShopSetting',
                params: params,
                headers: { shopObjectId: this.shopObjectId }
            }).then((res) => {
                this.orderShopSetting = res?.data?.data[0] || null
                this.loadingPage = false
            })
        },
        getSuggestPromotionTitle(type, results) {
            const title = util.generatePromotionResultTitle(type, results)
            return title
        },
        getSuggestPromotionDescription(condition) {
            const description = util.generatePromotionConditionTitle(condition)
            return description
        },
        getBase64Image(type) {
            const imagePath = `/img/promotion/type/${type}.jpg`;
            fetch(imagePath)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.promotionData.details.remoteImagePath = reader.result; // Base64 string
                    };
                    reader.readAsDataURL(blob);
                })
                .catch(error => {
                    this.promotionData.details.remoteImagePath = ''
                    console.error('Error loading image as Base64:', error);
                });
        },
        validateDetails() {
            if (this.promotionData.details.title.includes('null')) {
                this.promotionData.details.title = '';
            }
            if (this.promotionData.details.description.includes('null')) {
                this.promotionData.details.description = '';
            }
        }
    },
    watch: {
        shopObjectId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$router.push('/promotion')
            }
        },
    },
}
</script>
<style></style>